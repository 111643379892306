import { WMBaseItem } from "./baseitem";
const Utils = require("@/utils").default;
const GraphBuffer = require("@/recordings/graphbuffer").default;
import Network from "@/networking";

export class Project extends WMBaseItem {
  constructor(dto, identification) {
    super(dto);
    this.app_prop.graph_buffer = new GraphBuffer(this, window.app.$appm.net.graph_data);
  }

  get customerId() {
    return this.dto.customerId;
  }

  get zones() {
    return this.children.zones;
  }
  get cp_data() {
    return this.children.cp_data;
  }
  get recording_cache() {
    return this.app_prop.recording_cache;
  }
  get formulas() {
    return this.children.formulas;
  }
  get cntrl_devices() {
    return this.children.cntrl_devices;
  }
  get cntrls() {
    return this.children.cntrls;
  }
  get alarm() {
    return this.zones.some(z => z.dto.aggregateAlarmSignal == true);
  }
  get alarmUnack() {
    return this.zones.some(z => z.dto.aggregateAlarmUnack == true);
  }

  get lastSeen() {
    let lastSeen = null;
    // Find the latest last seen
    for (let cntrl of this.children.cntrls) {
      if (lastSeen == null)
        lastSeen = cntrl.dto.lastSeen;
      else {
        if (lastSeen < cntrl.dto.lastSeen)
          lastSeen = cntrl.dto.lastSeen;
      }
    }
    return lastSeen;
  }

  get item_type() {
    return "project";
  }

  get edit_locked() {
    return this.app_prop.edit_lock;
  }

  get graph_buffer() {
    return this.app_prop.graph_buffer;
  }

  get_project_time_str() {
    let time = new Date().toISOString();
    return window.app.langutil.print_date_time(
      time,
      { show_date: false, show_seconds: true },
      "prj"
    );
  }

  toggle_all_tree_open(value) {
    for (let controller of this.children.cntrls) {
      controller.app_prop.tree_open = value;
    }
    for (let zone of this.children.zones) {
      zone.app_prop.tree_open = value;
    }
    for (let node of this.all_nodes) {
      node.app_prop.tree_open = value;
    }
  }

  get all_nodes() {
    let result = [];
    for (let zone of this.zones) {
      result = result.concat(zone.nodes);
    }
    return result;
  }

  get longitude() {
    // return this.app_prop.longitude;
    return this.dto.info.longitude;
  }

  get latitude() {
    // return this.app_prop.latitude;
    return this.dto.info.latitude;
  }

  get customer() {
    return window.appm.get_customer(this.dto.customerId);
  }

  get customerName() {
    let name = "";
    if (this.customer != null) {
      name = this.customer.name;
    }
    return name;
  }

  get client() {
    return this.dto.info.client;
  }
  props_template() {
    return {
      tree_open: true,
      include_select: false,
      edit_lock: true,
      customer_name: "",
      time_offset_minutes: 0,
      criteria: "",
      coord: null,
      drawings: "",
      MMResponsibility: "",
      graph_buffer: null
    };
  }

  children_template() {
    return {
      formulas: [],
      cp_data: [],
      zones: [],
      cntrls: [],
      cntrl_devices: [],
      claimed_cntrl_devices: []
    };
  }

  delete_project() {
    Network.project.del(this.dto.customerId, this.dto.id);
  }

  get_grants(app) {
    return app.grants.filter(grant => grant.projectId == this.id);
  }

  support_user_exists(app) {
    let externalUsers = app.sltd.project
      .get_grants(app)
      .filter(grant => grant.collectionId == null);
    for (let grant of externalUsers) {
      let tempName = grant.locals.userName.toLowerCase();
      if (tempName == 'support') {
        return true;
      }
    }
    return false;
  }

  add_support_user(projectId) {
    var new_user = {
      email: "support@protector-technology.com",
      name: "Support",
      canView: true,
      canReport: true,
      canSetup: true,
      canDelete: true,
      canGrant: false
    };  
    Network.project_grant.update(projectId, null, new_user);
  }

  find_node(id) {
    for (var i = 0; i < this.children.zones.length; i++) {
      let node = this.children.zones[i].find_node_by_id(id);
      if (node != null) return node;
    }
    return null;
  }

  any_other_node_switch_off_on_alarm(cntrl_id, node_id) {
//    console.log("any_other_node_switch_off_on_alarm, cntrl_id:"+cntrl_id+", node_id:"+node_id);
    for (var i = 0; i < this.children.zones.length; i++) {
      for (let node of this.children.zones[i].nodes) {
//        console.log("node.dto.controllerId:"+node.dto.controllerId + " node.id:"+node.id);
        if (node.dto.controllerId == cntrl_id) {
//          console.log("node_id:"+node.id+", "+node.dto.psSwitchOffOnAlarm);
          // Check this controller only
          if (node.dto.psSwitchOffOnAlarm != null) {
            if (node.dto.psSwitchOffOnAlarm && (node.id != node_id)) {
              // found another node where switch off on alarm is active
              return true;
            }
          }
            
        }

      }

    }
    return false;    
  }

  find_channel(id) {
    for (var i = 0; i < this.children.zones.length; i++) {
      for (let node of this.children.zones[i].nodes) {
        for (let channel_index in node.channels) {
          if (node.channels[channel_index].id == id)
            return node.channels[channel_index];
        }
      }
    }
    return null;
  }

  find_zone(id) {
    return Utils.array_element_by_id(id, this.children.zones);
  }

  static new_item_instance(dto, identification) {
    return new Project(dto, identification);
  }

  // eslint-disable-next-line
  static find_parent(app, identification) {
    return app.projects;
  }

  // eslint-disable-next-line
  static find(app, id, identification) {
    return app.$appm.get_project(id);
  }
}
